import { graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container } from "react-bootstrap"
import { PageLinks } from "../common/site/page-static-links"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import MarketReport from "../components/MarketReportDetails/MarketReportDetails"
import SocialShareComponent from "../components/SocialShareComponent/SocialShareComponent"
import Layout from "../components/layout"
import { formTracking, removeDataAttributes } from "../components/Common/utils"
import SEO from "../components/seo"
import qs from "qs"
import logoBlack from "../images/logo-white.svg"


const MarketReportDetails = ({ data, pageContext}) => {
  const [pageData, setPageData] = useState( data?.strapiMarketReport)

  const marketData = pageData
  const windowSearch= typeof window!=="undefined"?window.location.search:""
  const [loading, setLoading] = useState(() => {
    const checkurlParams = new URLSearchParams(windowSearch);
    const checkisPreview = checkurlParams.get('debug') === 'preview';
    return checkisPreview;
  });
  const [isPreviewEnabled, setIsPreviewEnabled]=useState(false)
  const [enablePreview, setEnablePreview] = useState(pageContext?.strapi_preview == "true" ? true : false) // since its string


  const [shareIcons, setShareIcons] = useState(false)

  const openShareicons = () => {
    setShareIcons(true)
    if (shareIcons === true) {
      setShareIcons(false)
    }
    formTracking({
      event_tracking: "share-property",
      form_name: "Share Property ",
    })
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  const PageData=data?.allStrapiPage?.edges?.length>0?data?.allStrapiPage?.edges[0]?.node:"";

  let popUpData=""

  if(PageData?.add_page_modules?.length>0){
     const results=PageData?.add_page_modules?.filter(item=>item.__typename==="STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT")
     if(results?.length>0){
      popUpData=results[0]
     }
  }

  const query = qs.stringify(
    {
      populate: {
        ggfx_results:"*",
        pdf:"*",
        tail_image:"*",
        card_list:{
          populate:{
            items:{
              populate:{
                image:"*",
                link:{
                  populate:{
                    parent:"*"
                  }
                }
              }
            }
          }
        }
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );


  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get('debug') === 'preview';
    const apiUrl=`${process.env.GATSBY_STRAPI_SRC}/api/market-reports/${urlParams.get('strapi_id')}?${query}`

    const bearerToken = process.env.GATSBY_STRAPI_API_TOKEN;

    if(isPreview){
      setIsPreviewEnabled(true)
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        const getData = await response.json();
        const previewData=removeDataAttributes(getData)
        setLoading(false)
        setEnablePreview(false)
        let myPrevData;
        if(pageData){
          myPrevData = pageData
        } else {
          myPrevData = {};
          myPrevData.tail_image = {}
          myPrevData.card_list = {}
          myPrevData.card_list.items = []
          myPrevData.id = urlParams.get('strapi_id')
        }
        // console.log(previewData,"previewData")
        if(previewData){
          myPrevData.tail_image = previewData?.tail_image
          myPrevData.pdf = previewData?.pdf
          myPrevData.card_list = previewData?.card_list
          myPrevData.content = previewData?.content
          myPrevData.title = previewData?.title
          marketData.read_time = previewData?.read_time
          setPageData(pageData => ({ ...pageData, myPrevData }));
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };
    fetchData();
    }
    else{
      setLoading(false)
      setIsPreviewEnabled(false)
    }

  },[])

    if(loading){
      return (
        <section className={"loader-wrapper"}>
          <div id="loader-wrapper">
            <div id="loader" class="new-loader">
              <img
                className="logo-white loader-logo"
                src={logoBlack}
                alt="logo"
              />
            </div>
          </div>
        </section>
      )
    }

    if(enablePreview){
      return null
    }

  return (
    <Layout popUpData={popUpData} dark={true}>
      <div className="layout-padding-top"></div>
      <Container>
        <div className="breadcum-share-wrapper">
          <BreadcrumbModule
            mainparentname={`Resources`}
            mainparent={PageLinks.resources}
            parentname={`Market Reports`}
            type={"details-page"}
            parent={PageLinks.marketreport}
            pagename={marketData?.title}
          />
          <div className="share-news-details" onClick={() => openShareicons()}>
            <i className="icon grey-share" />
            <span>Share</span>
            {shareIcons && (
              <SocialShareComponent
                openShareicons={openShareicons}
                shareurl={pageurl}
              />
            )}
          </div>
        </div>
      </Container>

      <MarketReport isPreviewEnabled={isPreviewEnabled} marketData={marketData} />
    </Layout>
  )
}

export const Head = ({ data }) => {
  const marketInfo = data?.strapiMarketReport
  const metaTitle = marketInfo?.title;
  const metaDesc = marketInfo?.title;

  let isPreview=""
  if(typeof window!=="undefined"){
  const urlParams = new URLSearchParams(window.location.search);
   isPreview = urlParams.get('debug') === 'preview';
  }

  const [preMetaTitle, setPreMetaTitle] = useState("")


  useEffect(() => {
    const timer = setTimeout(() => {
      if (typeof window !== "undefined" && isPreview) {
        try {
            setPreMetaTitle(metaTitle)
        } catch (error) {
          console.error("Error parsing session storage data:", error);
        }
      }
    }, 2000);

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, [isPreview]);

  useEffect(() => {
    if(isPreview){
      document.title =  `${metaTitle} | haus & haus`;
    }
  }, [metaTitle]);


 let pageUrl = typeof window !== "undefined" ? window.location.href : ""
 //breadscrumb schema
 let dynamicmenu1 ="Home"
 let dynamicmenu1Url = `${process.env.GATSBY_SITE_URL}/`
 let dynamicmenu2 = "Resources"
 let dynamicmenu2Url=`${process.env.GATSBY_SITE_URL}/resources/`
 let dynamicmenu3 = "Market Reports"
 let dynamicmenu3Url = `${process.env.GATSBY_SITE_URL}/resources/market-reports/`
 let dynamicmenu4 = marketInfo?.title
 let dynamicmenu4Url = `${process.env.GATSBY_SITE_URL}/resources/market-reports/${marketInfo?.slug}/`

 let breadcrumbJson = {
   "@context": "https://schema.org",	
 "@type": "BreadcrumbList",	
 "@id": "BreadcrumbList",
 itemListElement: [	
   {	
     type: "ListItem",	
     position: 1,	
     name: dynamicmenu1,	
     item: dynamicmenu1Url	
   },	
 ]	
 }

 if(dynamicmenu2){
   breadcrumbJson.itemListElement.push(
     {
       type: "ListItem",	
       position: 2,	
       name: dynamicmenu2,	
       item: dynamicmenu2Url	
     }
   )
 }

 if(dynamicmenu3){
   breadcrumbJson.itemListElement.push(
     {
       type: "ListItem",	
       position: 3,	
       name: dynamicmenu3,	
       item: dynamicmenu3Url	
     }
   )
 } 

 if(dynamicmenu4 && dynamicmenu4Url){
   breadcrumbJson.itemListElement.push(
     {
       type: "ListItem",	
       position: 4,	
       name: dynamicmenu4,	
       item: dynamicmenu4Url	
     }
   )
 } 
 //-----------------------

  return (
    <>
      <SEO title={metaTitle} description={metaDesc}
        imageUrl="https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i/file_7035ecdafe.jpg?updated_at=2024-07-08T11:28:34.283Z"
      ></SEO>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbJson) }}
      />
    </>
  ) 
}

export default MarketReportDetails

export const query = graphql`
  query ($page_id: Int) {
    strapiMarketReport(strapi_id: { eq: $page_id }) {
      title
      slug
      content {
        data {
          content
        }
      }
      pdf {
        url
      }
      card_list {
        heading
        items {
          cta_title
          content {
            data {
              content
            }
          }
          custom_link
          link {
            slug
            strapi_parent {
              slug
            }
          }
          image {
            alternativeText
            url
          }
          link_type
        }
      }
    }
    allStrapiPage(filter: {layout: {eq: "market_report"}}) {
      edges {
        node {
          title
          add_page_modules {
            ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
              __typename
              ...GlobalPopUpFragment
            }
          }
        }
      }
    }
  }
`
