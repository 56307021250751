import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
// import { Document } from "react-pdf"
// import PDFViewer from "pdf-viewer-reactjs"
// import PdfViewerComponent from "../PdfViewerComponent"
// import { Document, Page, pdfjs } from "react-pdf"
import ScrollAnimation from "react-animate-on-scroll"
import SocialShareNew from "../SocialShare/SocialShareNew"
import "./MarketReportDetails.scss"
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
const {
  PreviewCTALink,
} = require("@starberry/gatsby-theme-utils/Modules/PreviewCTALinkModule")
const MarketReport = ({ marketData, isPreviewEnabled}) => {
  const [isPlay, setPlay] = useState(false)
  const { content, strapi_id, title } = marketData
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.onload = function () {
        var iframe = document.getElementById("myiframe")
        if(iframe){
        iframe.width = iframe.contentWindow.document.body.scrollWidth
        iframe.height = iframe.contentWindow.document.body.scrollHeight
        }
      }
    }
  }, [])
  return (
    <>
      <div className="news-details-banner">
        <ScrollAnimation
          animateIn="animate__slideInUp"
          animateOnce
          delay={100}
          offset={100}
        >
          <Container>
            <div className="new-details-banner-sections">
              <h1 className="news-heading">
                {title + " "}
                Market Report
              </h1>
              {(content?.data?.content || (isPreviewEnabled&&content))&&
                <p className="description podcast">
                  <ContentModule Content={content?.data?.content || content} />
                </p>
              }
              <img src={marketData?.pdf?.url} alt={title} className="pdf" />
              
            </div>
          </Container>
        </ScrollAnimation>
      </div>
      <div className={`news-static-wrapper`}>
        <ScrollAnimation
          animateIn="animate__slideInUp"
          animateOnce
          delay={100}
          offset={100}
        >
          <Container>
            <div className="static-sections_news">
              <div className="horizontal-line"> </div>
              <h3>Share</h3>
              <SocialShareNew
                iconClass="icon icon-share"
                shareText="Share:"
                news
              />
            </div>
          </Container>
        </ScrollAnimation>
      </div>
      <div className="card-icon-bk">
        <Container>
          <h4>{marketData?.card_list?.heading}</h4>
          <div className="card-icons-news">
            {marketData?.card_list?.items?.map((item, index) => {
              return (
                <ScrollAnimation
                  animateIn="animate__slideInUp"
                  animateOnce
                  delay={index * 100}
                  offset={100}
                >
                  <div className="item-list item-m">
                    <img src={item?.image?.url} alt={item?.cta_title} />
                    <div className="c_holder">
                    {(item?.content?.data?.content || (isPreviewEnabled&&item?.content))&&(
                      <p className="title">
                        <ContentModule Content={item?.content?.data?.content || item?.content} />
                      </p>
                    )}
                      <div className="ctas">
                        <span>
                          <PreviewCTALink
                            class=""
                            link={
                              item?.link
                                ? item?.link
                                : { external_link: item?.custom_link }
                            }
                            title={item.cta_title}
                            target_window={
                              item.link_type === "external" ? "new_window" : ""
                            }
                          />
                        </span>
                        <span className="arrow">
                          <i className="icon black-top-arrow" />
                        </span>
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>
              )
            })}
          </div>
        </Container>
      </div>
    </>
  )
}

export default MarketReport
